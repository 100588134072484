<template>
  <v-container id="dashboard" fluid>
    <iframe
      :src="goalsDashboardUrl"
      width="100%"
      height="800"
      allowtransparency
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Dashboard",
  computed: {
    ...mapGetters("generic", {
      agentDetail: "agentDetail",
    }),
    ...mapGetters("metabase", {
      goalsDashboardUrl: "goalsDashboardUrl",
    }),
  },
  mounted() {
    this.$store.dispatch("metabase/MOUNT_PBI_DASHBOARD")
  },
}
</script>
